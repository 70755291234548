@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-primary font-secondary bg-white;
  }
  .h1 {
    @apply text-[54px] lg:text-[108px] font-primary font-bold capitalize leading-[120%] tracking-[-0.05em] mb-2;
  }
  .h1-smaller {
    @apply text-[54px] lg:text-[84px] font-primary font-bold capitalize leading-[120%] tracking-[-0.05em] mb-2;
  }
  .h2 {
    @apply text-[40px] lg:text-[30px] font-primary font-bold capitalize leading-[120%] tracking-[-0.05em] mb-2;
  }
  .section {
    @apply h-screen w-screen;
  }
  .btn {
    @apply py-[22px] px-[20px] h-[40px]  md:py-[15px] md:px-[40px] md:h-[66px] rounded-lg md:rounded-2xl flex items-center justify-center text-base uppercase font-secondary font-semibold bg-primary text-white;
  }
}
